import { RiAdminFill, RiComputerFill, RiDashboardLine } from "react-icons/ri";

import { FiMoreHorizontal } from "react-icons/fi";
import { IoStorefrontSharp } from "react-icons/io5";
import {
  FaShoppingCart,
  FaUsers,
  FaTruckMoving,
  FaFileInvoiceDollar,
  FaInfoCircle,
  FaRunning,
  FaTicketAlt,
} from "react-icons/fa";

import { BiMobileAlt, BiWorld } from "react-icons/bi";
import { BsCreditCard } from "react-icons/bs";
import { CgGym, CgMenu } from "react-icons/cg";
import { GoSettings, GoSignOut } from "react-icons/go";
import { IoIosDesktop } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { HiOutlineDuplicate } from "react-icons/hi";
import {
  AiFillAppstore,
  AiFillCalendar,
  AiFillProfile,
  AiOutlineSearch,
} from "react-icons/ai";
import {
  ChevronDownIcon as ChakraChevronDownIcon,
  ChevronLeftIcon as ChakraChevronLeftIcon,
  ChevronRightIcon as ChakraChevronRightIcon,
  ChevronUpIcon as ChakraChevronUpIcon,
} from "@chakra-ui/icons";

export const DashboardIcon = RiDashboardLine;
export const AthletesIcon = FaRunning;
export const CustomersIcon = FaUsers;
export const ManagerIcon = RiAdminFill;
export const PaymentsIcon = BsCreditCard;
export const BlocksIcon = AiFillAppstore;
export const GymIcon = CgGym;
export const CalendarIcon = AiFillCalendar;
export const WorkoutSettingsIcon = GoSettings;
export const WebsiteIcon = RiComputerFill;
export const VoucherIcon = FaTicketAlt;
export const AthleteProfileIcon = AiFillProfile;

export const OrdersIcon = FaShoppingCart;
export const StoreIcon = IoStorefrontSharp;
export const WebIcon = BiWorld;
export const ShippingIcon = FaTruckMoving;
export const SubscriptionPlansIcon = FaFileInvoiceDollar;
export const BrandIcon = FaInfoCircle;

export const SearchIcon = AiOutlineSearch;
export const MoreIcon = FiMoreHorizontal;
export const EditIcon = MdEdit;
export const MenuIcon = CgMenu;
export const DuplicateIcon = HiOutlineDuplicate;

export const MobileIcon = BiMobileAlt;
export const DesktopIcon = IoIosDesktop;

export const ChevronLeftIcon = ChakraChevronLeftIcon;
export const ChevronRightIcon = ChakraChevronRightIcon;
export const ChevronUpIcon = ChakraChevronUpIcon;
export const ChevronDownIcon = ChakraChevronDownIcon;

export const SignOutIcon = GoSignOut;

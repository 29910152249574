import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";

import { useMenuStore } from "lib/stores/MenuStore";
import { useUser } from "src/contexts/useUser";

export default function SidebarLayout({ children, sidebar }: any) {
  const menuStore = useMenuStore();
  const { isAdmin } = useUser();

  return (
    <Flex
      minH={"100vh"}
      flexDir={"row"}
      align="flex-start"
      maxW="100vw"
      alignItems={"stretch"}
    >
      <Box
        h="full"
        position="fixed"
        minW={"10px"}
        minH={"100%"}
        bgColor={"brandBlue.500"}
      />
      <Flex
        ml="10px"
        position="sticky"
        top="0"
        mr="5"
        bgColor={isAdmin ? "brandBlue.900" : undefined}
      >
        {sidebar}
      </Flex>

      <Flex
        grow={1}
        maxW={`calc(100vw - ${menuStore.isOpen ? "350px" : "50px"} - 0.5rem)`}
      >
        {children}
      </Flex>
    </Flex>
  );
}

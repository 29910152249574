import { createStore, useStore } from "react-hookstore";

export interface IAuthStoreState {
  isOpen: boolean;
  [label: string]: boolean;
}

const MenuStore = createStore<IAuthStoreState>("MenuStore", {isOpen: true});

export const useMenuStore = () => {
  const [menuStore] = useStore(MenuStore);
  return menuStore;
};

export const ToggleMenu = () => {
  MenuStore.setState({ ...MenuStore.getState(), isOpen: !MenuStore.getState().isOpen});
};

export const SetSubmenuOpen = (menuId: string, isOpen: boolean) => {
  MenuStore.setState({ ...MenuStore.getState(), [menuId]: isOpen });
};

export default MenuStore;

import type { ComponentProps } from "react";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import type {
  BoxProps
} from "@chakra-ui/react";
import {
  Box,
  Text,
  Icon,
  HStack,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Image,
  LinkBox,
  LinkOverlay,
  useMultiStyleConfig,
  StylesProvider,
  Heading,
  IconButton,
  VStack,
} from "@chakra-ui/react";

import { NavLink } from "components/Link";
import Link from "next/link";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useRouter } from "next/router";
import {
  SetSubmenuOpen,
  ToggleMenu,
  useMenuStore,
} from "src/lib/stores/MenuStore";
import { ChevronRightIcon } from "components/Icons/Icons";
import { getPaths } from "lib/utils/paths";
import { LINKS } from "lib/utils/constants";
import { useUser } from "src/contexts/useUser";

const InactiveTextColor = "gray.400";
const ActiveTextColor = "gray.500";

interface IMenu extends BoxProps {
  size?: string;
  variant?: string;
  title: string;
}

const Menu = ({ children, size, title, variant, ...props }: IMenu) => {
  // eslint-disable-next-line i18next/no-literal-string
  const styles = useMultiStyleConfig("Menu", { size, variant });
  const menuStore = useMenuStore();

  if (!menuStore.isOpen) {
    return (
      <Box
        pos="relative"
        color={InactiveTextColor}
        {...props}
        sx={{
          ...styles.menu,
          minW: "50px",
          w: "50px",
        }}
      >
        <StylesProvider value={styles}>
          <MenuToogleButton />
          <MenuTitle
            transform="rotate(-90deg)"
            pos="absolute"
            top="180px"
            left="-18px"
          >
            {title}
          </MenuTitle>
        </StylesProvider>
      </Box>
    );
  }
  return (
    <Box color={InactiveTextColor} {...props} sx={styles.menu}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  );
};

const MenuToogleButton = () => {
  const menuStore = useMenuStore();

  return (
    <Box pos="absolute" top={5} right={5}>
      <IconButton
        onClick={ToggleMenu}
        rounded="full"
        size="sm"
        aria-label="Toggle menu"
        icon={
          menuStore.isOpen ? (
            <ChevronLeftIcon fontSize="2xl" />
          ) : (
            <ChevronRightIcon fontSize="2xl" />
          )
        }
      />
    </Box>
  );
};

const MenuTitle = ({ children, ...props }: any) => {
  const router = useRouter();
  const vertical = !!props.transform;

  return (
    <MenuSection>
      <VStack align="flex-start" spacing={5}>
        <HStack
          {...props}
          w={vertical ? undefined : "100%"}
          spacing={3}
          px={2}
          alignItems="flex-end"
          justifyContent={vertical ? undefined : "center"}
          as="button"
          onClick={() => router.push(getPaths.index())}
        >
          <Image
            // eslint-disable-next-line i18next/no-literal-string
            alt="myHeart logo"
            src={LINKS.images.miniLogo}
            h={"36px"}
            objectFit="contain"
            objectPosition="left"
          />
          <Heading fontSize={"2xl"} color={ActiveTextColor} letterSpacing={1}>
            {children}
          </Heading>
        </HStack>
        <HStack w="100%" justify="center">
          <Image
            // eslint-disable-next-line i18next/no-literal-string
            alt="pwrdBy_strava"
            src={LINKS.images.poweredByStrava}
            h={"26px"}
            objectFit="contain"
          />
        </HStack>
      </VStack>
    </MenuSection>
  );
};

const MenuSection = ({ children }: any) => {
  return <Box my={12}>{children}</Box>;
};

const MenuLabel = ({ label }: { label: string }) => {
  return (
    <Text
      textTransform="uppercase"
      fontSize={"xs"}
      fontWeight={"bold"}
      letterSpacing={1}
      mb={3}
    >
      {label}
    </Text>
  );
};

interface IMenuItemProps extends ComponentProps<any> {
  label: string;
  href?: string;
  icon: any;
}

const MenuItem = ({ label, href, icon, ...props }: IMenuItemProps) => {
  const router = useRouter();
  const isActive =
    !!href &&
    (href === router.pathname ||
      (href !== getPaths.index() && router.pathname.includes(href || "")));

  const stack = (
    <HStack align="center" spacing={6} h={"55px"} px={2} as="button" {...props}>
      <Icon
        as={icon}
        fontSize={20}
        color={isActive ? "brandBlue.500" : InactiveTextColor}
      />
      <MenuItemText label={label} isActive={isActive} />
    </HStack>
  );

  if (href) {
    return <NavLink url={href}>{stack}</NavLink>;
  }

  return stack;
};

const MenuItemText = ({
  label,
  isActive,
}: {
  label: string;
  isActive?: boolean;
}) => (
  <Text fontWeight="600" color={isActive ? "brandBlue.500" : InactiveTextColor}>
    {label}
  </Text>
);

const MenuGroup = ({
  id,
  header,
  children,
}: {
  id: string;
  header: any;
  children: any;
}) => {
  const [reducedMotion, setReducedMotion] = useState(true);
  const menuStore = useMenuStore();
  const isOpen = menuStore[id];
  const { isAdmin } = useUser();

  useEffect(() => {
    setTimeout(() => setReducedMotion(false), 1000);
  }, []);

  return (
    <Accordion
      allowToggle
      variant={"menuGroup"}
      defaultIndex={isOpen ? 0 : -1}
      reduceMotion={reducedMotion}
      onChange={newState => SetSubmenuOpen(id, +newState >= 0)}
    >
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <AccordionItem isFocusable={false} boxShadow={isOpen ? "sm" : "none"}>
        <AccordionButton>
          <Box flexGrow={1}>{header}</Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel
          bgColor={isAdmin ? "gray.600" : undefined}
          mb="5"
        >
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const MenuGroupHeader = ({ label, icon }: IMenuItemProps) => (
  <HStack align="center" spacing={6} h={"55px"} px={2}>
    <Icon
      as={icon}
      fontSize={20}
      sx={{
        "[aria-expanded=true] &": {
          color: ActiveTextColor,
        },
      }}
    />
    <Text
      fontWeight="600"
      color={InactiveTextColor}
      textAlign={"left"}
      sx={{
        "[aria-expanded=true] &": {
          color: ActiveTextColor,
        },
      }}
    >
      {label}
    </Text>
  </HStack>
);

interface MenuGroupItemProps {
  href: string;
  label: string;
}
const MenuGroupItem = ({ href, label }: MenuGroupItemProps) => {
  return (
    <NavLink url={href}>
      <HStack align="center" spacing={6} h={"40px"} px={2}>
        <Box
          w={"7px"}
          h={"7px"}
          mt={"2px"}
          ml={"-8px"}
          mr={"5px"}
          borderRadius={"7px"}
          border={"solid 2px"}
          borderColor={"gray.300"}
          sx={{
            ".active &": {
              bgColor: "brandBlue.500",
              borderColor: "brandBlue.500",
            },
          }}
        />
        <Text
          fontWeight="600"
          fontSize="sm"
          color={InactiveTextColor}
          sx={{
            ".active &": {
              color: ActiveTextColor,
            },
          }}
        >
          {label}
        </Text>
      </HStack>
    </NavLink>
  );
};

interface MenuLanguageGroupItemProps {
  url: string | Record<string, any>;
  label: string;
  flag?: string;
}
const MenuLanguageGroupItem = ({ url, label, flag }: MenuLanguageGroupItemProps) => {
  return (
    <NavLink url={url} locale={flag}>
      <HStack align="center" spacing={6} h={"40px"} px={2}>
        {flag ? (
          <ReactCountryFlag
            countryCode={flag === "en" ? "GB" : flag}
            width="20px"
          />
        ) : (
          <Box
            w={"7px"}
            h={"7px"}
            mt={"2px"}
            ml={"-8px"}
            mr={"5px"}
            borderRadius={"7px"}
            border={"solid 2px"}
            borderColor={"gray.300"}
            sx={{
              ".active &": {
                bgColor: "brandBlue.500",
                borderColor: "brandBlue.500",
              },
            }}
          />
        )}
        <Text
          fontWeight="600"
          fontSize="sm"
          color={InactiveTextColor}
          sx={{
            ".active &": {
              color: ActiveTextColor,
            },
          }}
        >
          {label}
        </Text>
      </HStack>
    </NavLink>
  );
};

const MenuBackButton = ({ children, href }: React.ComponentProps<any>) => {
  return (
    <MenuSection>
      <LinkBox
        bgColor="white"
        py={3}
        mx={"-20px"}
        px={"20px"}
        _hover={{
          boxShadow: "sm",
          transform: "translateY(-1px);",
        }}
        transition="all 300ms ease;"
      >
        <LinkOverlay
          href={href}
          as={Link}
          fontWeight={"bold"}
          _hover={{ textDecoration: "none" }}
        >
          <HStack align={"center"}>
            <Icon as={ChevronLeftIcon} fontSize={"3xl"} ml={1.5} mr={3} />
            <Text as="span">{children}</Text>
          </HStack>
        </LinkOverlay>
      </LinkBox>
    </MenuSection>
  );
};

export {
  Menu,
  MenuTitle,
  MenuSection,
  MenuItem,
  MenuLabel,
  MenuGroup,
  MenuGroupItem,
  MenuLanguageGroupItem,
  MenuGroupHeader,
  MenuBackButton,
  MenuToogleButton,
};

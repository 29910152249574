import React from "react";
import { VStack } from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import ReactCountryFlag from "react-country-flag";
import { FiHelpCircle } from "react-icons/fi";
import { useRouter } from "next/router";

import {
  Menu,
  MenuSection,
  MenuItem,
  MenuLabel,
  MenuGroup,
  MenuGroupItem,
  MenuLanguageGroupItem,
  MenuGroupHeader,
  MenuTitle,
  MenuToogleButton,
} from "./Menu";
import SidebarLayout from "../SidebarLayout";
import {
  AthleteProfileIcon,
  AthletesIcon,
  BlocksIcon,
  CustomersIcon,
  DashboardIcon,
  GymIcon,
  PaymentsIcon,
  SignOutIcon,
  WebsiteIcon,
  WorkoutSettingsIcon,
} from "../../Icons/Icons";
import { LINKS } from "lib/utils/constants";
import { getPaths } from "lib/utils/paths";
import { useUser } from "src/contexts/useUser";
import { useLocales } from "src/contexts/LocalesContext";

export default function AdminSidebarLayout({ children }: any) {
  const { isAdmin, coach, signOut, user_type } = useUser();
  const { locale, route, query } = useRouter();
  const { locales } = useLocales();
  const t = useTranslations("components.layout.adminSidebarLayout");

  // capitalizeFirstLetter
  const title = user_type?.charAt(0).toUpperCase() + user_type?.slice(1);

  return (
    <SidebarLayout
      sidebar={
        <VStack w="full" flex={1} justify="space-between" pb={5}>
          <Menu title={title}>
            <MenuToogleButton />
            <MenuTitle>{title}</MenuTitle>
            <MenuSection>
              <MenuItem label={t("dashboard")} href={getPaths.index()} icon={DashboardIcon} />
            </MenuSection>
            <MenuSection>
              <MenuLabel label={t("athletes")} />
              <MenuItem label={t("athletes")} href={getPaths.atletas.index()} icon={AthletesIcon} />
              {isAdmin && (
                <MenuItem
                  label={t("athleteProfiles")}
                  href={getPaths.perfisAtleta.index()}
                  icon={AthleteProfileIcon}
                />
              )}
            </MenuSection>

            <MenuSection>
              <MenuLabel label={t("workouts")} />
              <MenuItem
                label={t("blocks")}
                href={getPaths.treinos.blocos.index()}
                icon={BlocksIcon}
              />
              {(isAdmin || coach?.platform_content_access) && (
                <MenuGroup
                  id="circuitos"
                  header={<MenuGroupHeader label={t("circuits")} icon={GymIcon} />}
                >
                  <MenuGroupItem
                    label={t("flexibility")}
                    // eslint-disable-next-line i18next/no-literal-string
                    href={getPaths.circuitos.type("flexibility")}
                  />
                  <MenuGroupItem
                    label={t("weightTraining")}
                    // eslint-disable-next-line i18next/no-literal-string
                    href={getPaths.circuitos.type("weight_training")}
                  />
                </MenuGroup>
              )}
              {isAdmin && (
                <MenuGroup
                  id="parametros"
                  header={
                    <MenuGroupHeader
                      label={t("workoutSettings")}
                      icon={WorkoutSettingsIcon}
                    />
                  }
                >
                  <MenuGroupItem
                    label={t("sports")}
                    href={getPaths.parametrosTreino.modalities()}
                  />
                  <MenuGroupItem
                    label={t("terrains")}
                    href={getPaths.parametrosTreino.terrains()}
                  />
                  <MenuGroupItem
                    label={t("altimetries")}
                    href={getPaths.parametrosTreino.altimetries()}
                  />
                  <MenuGroupItem
                    label={t("workoutZones")}
                    href={getPaths.parametrosTreino.zones()}
                  />
                  <MenuGroupItem
                    label={t("workoutDurations")}
                    href={getPaths.parametrosTreino.durations()}
                  />
                </MenuGroup>
              )}
            </MenuSection>

            {isAdmin && (
              <MenuSection>
                <MenuLabel label={t("administrator")} />
                <MenuItem
                  icon={CustomersIcon}
                  label={t("coaches")}
                  href={getPaths.treinadores.index()}
                />
                <MenuItem
                  icon={PaymentsIcon}
                  label={t("vouchers")}
                  href={getPaths.planosSubscricoes.vouchers.index()}
                />
                {/* <MenuGroup
                  id="planos"
                  header={
                    <MenuGroupHeader
                      label={"Planos e Subcrições"}
                      icon={PaymentsIcon}
                    />
                  }
                >
                  <MenuGroupItem
                    label={"Planos de Subscrição"}
                    href={getPaths.planosSubscricoes.planos.index()}
                  />
                  <MenuGroupItem
                    label={"Subscrições"}
                    href={getPaths.planosSubscricoes.subscricoes.index()}
                  />
                  <MenuGroupItem
                    label={"Vouchers"}
                    href={getPaths.planosSubscricoes.vouchers.index()}
                  />
                </MenuGroup> */}
                <MenuGroup
                  id="website"
                  header={
                    <MenuGroupHeader label={t("website")} icon={WebsiteIcon} />
                  }
                >
                  <MenuGroupItem
                    label={t("reviews")}
                    href={getPaths.website.testemunhos.index()}
                  />
                </MenuGroup>
              </MenuSection>
            )}
            <MenuSection>
              {coach && (
                <MenuItem
                  href={getPaths.treinadores.id(coach?.id)}
                  label={t("profile")}
                  icon={CustomersIcon}
                />
              )}
              <MenuGroup
                id="languages"
                header={
                  <MenuGroupHeader
                    label={t("language")}
                    icon={() => <ReactCountryFlag
                      countryCode={locale === "en" ? "GB" : locale}
                      width="20px"
                    />}
                  />
                }
              >
                {locales.map(({ code: locale }) => (
                  <MenuLanguageGroupItem
                    key={locale}
                    url={{ pathname: `/${locale}${route}`, query }}
                    label={t(locale)}
                    flag={locale}
                  />
                ))}
              </MenuGroup>
              {coach && (
                <MenuItem
                  label={t("needHelp")}
                  href={LINKS.MyHeartEmailMailTo}
                  icon={FiHelpCircle}
                />
              )}
              <MenuItem
                label={t("signOut")}
                onClick={signOut}
                icon={SignOutIcon}
              />
            </MenuSection>
          </Menu>
        </VStack>
      }
    >
      {children}
    </SidebarLayout >
  );
}

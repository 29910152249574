import type { ComponentProps } from "react";
import React from "react";
import type {
  ChakraProps
} from "@chakra-ui/react";
import {
  Link as ChackaLink,
  LinkOverlay as ChakraLinkOverlay,
  Box
} from "@chakra-ui/react";

import NextLink from "next/link";

interface IProps extends ComponentProps<any>, ChakraProps {
  href: string;
  locale?: string;
}

const Link = ({ href, ...props }: IProps) => {
  return (
    <NextLink href={href} passHref>
      <Box cursor="pointer" color="brandBlue.500" {...props}>
        {props.children}
      </Box>
    </NextLink>
  );
};

export const LinkOverlay = (props: IProps) => {
  return (
    <ChakraLinkOverlay
      _hover={{ textDecor: "none" }}
      as={NextLink}
      {...props}
      passHref
    >
      {props.children}
    </ChakraLinkOverlay>
  );
};

interface NavLinkProps {
  url: string | Record<string, any>;
  locale?: string;
  children: React.ReactNode;
}

export const NavLink = ({ locale, url, ...props }: NavLinkProps) => {
  return (
    <NextLink passHref href={url} locale={locale}>
      <Box cursor="pointer" _hover={{ color: "brandBlue.600" }} {...props}>
        {props.children}
      </Box>
    </NextLink>
  );
};

export default Link;
